import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css';
import './index.css'
import App from './App'



function refresh_data(){
  var request = new XMLHttpRequest()
  request.open('GET', 'https://backend-api-5jonftdk7a-de.a.run.app ', true)

  request.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        window.userData = JSON.parse(request.responseText)
        ReactDOM.render(<App />, document.getElementById('root'))
      }
  };

  request.send()
}

refresh_data();
setInterval(refresh_data, 30000);