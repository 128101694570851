import React, {Component} from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Image from 'react-bootstrap/Image'

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th></th>
        <th style={{textAlign: 'center'}}>Name</th>
        <th style={{textAlign: 'center'}}>XP This Level</th>
        <th style={{textAlign: 'center'}}>Progress</th>
        <th style={{textAlign: 'center'}}>Level</th>
      </tr>
    </thead>
  )
}

const TableBody = (props) => {
  props.userData.sort((a, b) => {
    return b.txp - a.txp;
  });

  const rows = props.userData.map((row, index) => {
    let xpForNextLev = 1.35 ** ((row.level) / 10) * 1200;
    let progress = row.rxp / xpForNextLev

    if (!row.avatar){
      row.avatar = 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png'
    }
    return (
      <tr key={index}>
        <td width={"10%"} style={{textAlign: 'center', fontFamily: 'Luminari', fontSize: '10px'}}><Image src={row.avatar} style={{height:'80px', width:'auto'}}/>{row.fantasy_role}</td>
        <td style={{textAlign: 'center', fontSize: '21px', fontFamily: 'Playfair Display', width: '450px'}}>{row.name}</td>
        <td style={{textAlign: 'center', width: '350px', fontSize: '17px', fontFamily: 'Luminari'}}> {Math.round(row.rxp)} / {Math.round(xpForNextLev)}</td>
        <td style={{textAlign: 'center', width: "300px"}}>  <ProgressBar now={100 * progress} style={{fontFamily: 'Luminari'}} label={`${Math.round(progress * 100)}%`}/> </td>
        <td style={{textAlign: 'center', width: '200px', fontSize: '40px', fontFamily: 'Luminari'}}>{row.level}</td>

      </tr>
    )
  })
  return <tbody>{rows}</tbody>
}

class Table extends Component {
  render() {
    const {userData} = this.props
    return (
      <table>
        <TableHeader />
        <TableBody userData = {userData}/>
      </table>
    )
  }
}

export default Table
