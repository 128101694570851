import React, {Component} from 'react'
import Table from './Table'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';



class App extends Component {
  render() {

    return (
      <div className="container">
        <Button variant='link' style={{backgroundColor: 'white', color: "#404040", textAlign: 'center', float: 'left', fontSize: '15px', fontWeight: '500'}} onClick={()=>{window.location.replace("http://taskmagic-admin-console.web.app");}} >Admin Console</Button>    
        <h2 style={{fontSize: '15px', textAlign: 'right', alignSelf: 'stretch'}}>HKMCI | TaskMagic</h2>
        <Table userData = {window.userData}/>
        
      </div>
    )
  }
}


export default App
